import { Component, OnInit, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ApiCallsService } from "@app/routes/dashboard/pages/dashboard/api-call-service";
import { UtilsService } from "@app/core/utils.service";
@Component({
  selector: "app-history-dialogcharts",
  templateUrl: "./history-dialogcharts.component.html",
  styleUrls: ["./history-dialogcharts.component.css"],
})
export class HistoryDialogchartsComponent implements OnInit {
  // [x: string]: any;
  token = localStorage.getItem("user");
  selectedMIX: any = [];
  selectedMIXID = "DASB198960";
  selectedMixID: any = [];
  selectedJobno: any = [];
  selectedJOBNO: any = [];
  selectedPlanttype: any = [];
  selectPlantType: any = [];
  appliedDateFilters: any = {};
  MIXData: any = [];
  selectedPlant = "TCII";
  selectedJOB = "09-023";
  UnionAlertsData: any = [];
  selectonemixid: any[];
  ListMixID: any = [];
  selectlistmixid: any;
  perfEffncyData: any;
  qeDeviationIndexData: any;
  EnergyDeviationIndexData: any[];
  carbonindexdata: any;
  OEEData: any;
  EneData: any;
  TPData: any[];
  StatusType: any;
  keys: string[];
  keyValuePairs: { key: string; values: any }[] = [];
  MixIdNames: any[];
  Mixlistdata: any;
  mixIdData: any;
  Mixlisttopdata: any;
  SumData: any;
  RoleId: any;
  OperatorRole: any;
  usernamedisplay: any;
  selectDPlantType: any;
  isDefinedFilterSelected: boolean = false;
  selectedDefaultDate: string;
  clicked: any;
  gaspertondata: any;
  GCperdata: any;
  GCfdata1: any[];
  GCIdperNames: any[];
  GCtonName: any[];
  ProdEC: any;
  GCData: any[];
  GCfdata: any[];
  GCIdNames: any[];
  ProdHours: any;
  ProdTP: any;
  Valueschanged: any;
  ProdLoss: any;
  ProdOEE: any;
  IsDropdownTrue: boolean = true;
  ProdUnifiedData: any;
  ProductionHrs: any;
  ProdEff: any;
  QualEff: any;
  EnergyEff: any;
  CarbonEff: any;
  TPdata: any;
  TPIdNames: any;
  TPName: any;
  mergedData: any;
  GADIdnames: any;
  DrumName: any;
  AmbName: any;
  EnergyUnifiedData: any;
  ThroughputGas: any;
  GasDrumData: any;
  GasAmbData: any;
  GADdata: any[];
  constructor(
    public dialogRef: MatDialogRef<HistoryDialogchartsComponent>,
    readonly apiCallsService: ApiCallsService,
    readonly utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.selectonemixid = this.data.SelectedMixId;
  
    if (this.utils.dateFilter) {
      this.isDefinedFilterSelected = false;
      this.appliedDateFilters["start_date"] = new Date(this.utils.dateFilter["start_date"]);
      this.appliedDateFilters["end_date"] = new Date(this.utils.dateFilter["end_date"]);
    } else {
      this.selectedDefaultDate = "Yesterday";
      const currentDate = new Date();
      const oneDayInMillis = 24 * 60 * 60 * 1000;
      this.appliedDateFilters["start_date"] = new Date(currentDate.getTime() - 7 * oneDayInMillis);
      this.appliedDateFilters["end_date"] = new Date(currentDate.getTime() - oneDayInMillis);
    }
  
    switch (this.data.clicked) {
      case 1:
        this.ProdEff = this.data.graphData;
        this.drawProductionchart();
        break;
      case 3:
        this.QualEff = this.data.graphData;
        this.drawQualityeffncyChart();
        break;
      case 4:
        this.EnergyEff = this.data.graphData;
        this.drawEnergyeffncyChart();
        break;
      case 5:
        this.CarbonEff = this.data.graphData;
        this.drawCarbonIndexchart();
        break;
      case 6:
        this.getunionAlert();
        break;
      case 7:
        this.ProdOEE = this.data.graphData;
        this.drawProdOEEchart();
        break;
      case 8:
        this.ProdTP = this.data.graphData;
        this.drawProdTPchart();
        break;
      case 9:
        this.GCfdata = this.data.graphData;
        this.GCIdNames = this.data.graphGCNames;
        this.GCtonName = this.data.graphgcperton;
        this.DrawChartGC();
        break;
      case 10:
        this.ProdLoss = this.data.graphData;
        this.drawProdlosschart();
        break;
      case 11:
        this.DrawPvsNP();
        break;
      case 12:
      case 13:
        this.ProdHours = this.data.graphData;
        setTimeout(() => {
          this.data.clicked == 12 ? this.DrawProdGasQnty() : this.DrawNonProdGasQnty();
        }, 100);
        console.log(" this.data.clicked",  this.data.clicked)
        break;
      case 14:
        this.mixIdData = this.data.graphData;
        this.MixIdNames = this.data.graphMixIdNames;
        this.DrawMixID();
        this.keysnValues();
        break;
      case 15:
        this.GCfdata = this.data.graphData;
        this.DrawChartGCNP();
        break;
        case 16:
          this.TPdata = this.data.graphData;
          this.TPIdNames = this.data.graphTPnames;
          this.TPName = this.data.graphTPnamedata;

          this.DrawChartTP();
          console.log(" this.TPdata", this.TPName,  this.TPIdNames,this.TPdata, this.data.clicked)
          break;
          case 17:
            this.mergedData = this.data.graphData;
            this.GADIdnames = this.data.graphGASidname;
            this.DrumName = this.data.graphDrumname;
            this.AmbName = this.data.graphAmbname;
            console.log(" this.mergedData",this.mergedData, this.data.clicked)
            this.DrawChartGAD();
            break;
            case 18:
              this.mergedData = this.data.graphData;
              this.GADIdnames = this.data.graphGASidname;
              this.DrumName = this.data.graphDrumname;
              this.AmbName = this.data.graphAmbname;
              console.log(" this.mergedData",this.mergedData, this.data.clicked)
              this.DrawChartNPGAD();
              break;
      default:
        break;
    }
  }
  

  closed() {
    this.dialogRef.close();
  }

  parseToken() {
    let token = this.token;
    const tokenPayload = token.split(".")[1];
    const decodedPayload = atob(tokenPayload);
    const decodedPayloadJson = JSON.parse(decodedPayload);
    this.RoleId = decodedPayloadJson.role;
    this.usernamedisplay = decodedPayloadJson.username;
    this.OperatorRole = decodedPayloadJson.entity_id;
    this.selectedMIX = decodedPayloadJson.mix_id;
    this.selectedMixID = this.selectedMIX[0];
    this.selectedJobno = decodedPayloadJson.job_no;
    this.selectedJOBNO = this.selectedJobno[0];
    this.selectedPlanttype = decodedPayloadJson.plant_type;
    // this.selectDPlantType = this.selectedPlanttype[0];
    if (this.selectedPlanttype[0] == "TCII") {
      this.selectDPlantType = this.selectedPlanttype[0];
    } else {
      this.selectDPlantType = null;
    }
  }
  //  MixId list Dropdown Api Call
  getMIXidList() {
    let startDate = new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime();
    let endDate = new Date(
      new Date(this.appliedDateFilters["end_date"]).setHours(23, 59, 59, 999)
    ).getTime();
    this.apiCallsService
      .MIxidlist(startDate, endDate, this.selectDPlantType)
      .subscribe(
        (res) => {
          this.ListMixID = res;
          this.selectlistmixid = this.ListMixID[0].mix_id;
          this.selectonemixid = this.selectlistmixid[0];
        },
        (err) => {
          this.ListMixID = [];
          console.log(err);
        }
      );
  }
  changeMixId(types: any , action: string) {
    this.selectonemixid = [];
    this.ListMixID = types.target.value;
    this.selectonemixid = this.ListMixID;
    this.getUnifiedData();
    setTimeout(() => {
      this.getEneConsmpData();
    }, 300);
    
    // switch (action) {
    //   case 'getprodTP':
    //     this.getprodTP();
    //     break;
    //   case 'getMixList':
    //     this.getMixList();
    //     break;
    //   case 'getprodOEE':
    //     this.getprodOEE();
    //     break;
    //     case 'getProdloss':
    //       this.getProdloss();
    //       break;
    //       case 'getprodEC':
    //     this.getprodEC();
    //     break;
    //     case 'getProdHours':
    //       this.getProdHours();
    //       break;
    //   default:
    //     // Handle any other actions or leave it empty if not needed.
    //     break;
    // }
  }

  /* Api calls and Graphs functions for dropdown */

  getUnifiedData(){
    let startDate = new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime();
    let endDate = new Date(
      new Date(this.appliedDateFilters["end_date"]).setHours(23, 59, 59, 999)
    ).getTime();
    this.StatusType = "P";
    this.selectDPlantType = this.data.statusSelectedType;
    this.apiCallsService
      .HistoryProdData(
        startDate,
        endDate,
        this.selectonemixid,
        this.selectDPlantType,
        this.selectedJOB,
        this.StatusType
      )
      .subscribe(
        (res) => {
          this.ProdUnifiedData = res;
          this.ProductionHrs= this.ProdUnifiedData.OverallUtilization;
          this.ProdHours = this.ProdUnifiedData.ProductionDaily;
          this.ProdOEE = this.ProdUnifiedData.OEE;
          this.ProdTP= this.ProdUnifiedData.Throughput;
          this.ProdLoss= this.ProdUnifiedData.Losses;
          this.Mixlistdata=this.ProdUnifiedData.MixTonsRun;
          this.ProdEC=this.ProdUnifiedData.GasConsumption;
         
          
         
          setTimeout(() => {
            this.getMixIdData();
            this.getmixidnames();
            this.DrawMixID();
          }, 100);
          setTimeout(() => {
            this.getGCdata();
            this.getGCdatanames();
            this.getGCtondatanames();
            if(this.StatusType == "NP"){
            this.DrawChartGCNP();
            } else{
              this.DrawChartGC();
            }
          }, 100);
  
          setTimeout(() => {
            this.DrawPvsNP();
            if(this.StatusType == "NP"){
              this.DrawNonProdGasQnty();
              } else{
                this.DrawProdGasQnty();
              }
            
            this.drawProdOEEchart();
            this.drawProdTPchart();
            this.drawProdlosschart();
         }, 1000);
        
         
        },
        (err) => {
          this.ProdUnifiedData = [];
          console.log(err);
        }
      );
  }

  getEneConsmpData() {
    let startDate = new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime();
    let endDate = new Date(
      new Date(this.appliedDateFilters["end_date"]).setHours(23, 59, 59, 999)
    ).getTime();
    this.apiCallsService
      .EnergyConsumptionGraph(
        startDate,
        endDate,
        this.selectonemixid,
        this.selectDPlantType,
        this.selectedJOB,
        this.StatusType
      )
      .subscribe(
        (res) => {
          this.EnergyUnifiedData = res;
          this.ProdHours = this.EnergyUnifiedData.ProductionDaily;
          this.ProdEC = this.EnergyUnifiedData.GasConsumption;
          this.ThroughputGas = this.EnergyUnifiedData.Throughput_GasConsumption;
          console.log("log", this.ThroughputGas,  this.ProdEC )
          this.GasDrumData =  this.EnergyUnifiedData.DrumshellTemp_Comparison.final_data;
          this.GasAmbData =  this.EnergyUnifiedData.Ambient_Temp_comparison.final_data;
          // this.noDataAvailable1 = this.ProductionHrs.length === 0 || this.ProductionHrs === null;
          // this.noDataAvailable2 = this.ProdHours.length === 0 || this.ProdHours === null;
          // this.noDataAvailable6 = this.ProdEC.length === 0 || this.ProdEC === null;
    
     
         
        
          setTimeout(() => {
            this.getGCdata();
            this.getGCdatanames();
            this.getGCtondatanames();
            if (this.StatusType == "NP") {
              this.DrawChartGCNP();
              this.DrawNonProdGasQnty();
            } else {
              this.DrawChartGC();
              this.DrawProdGasQnty();
            }
          }, 100);
          setTimeout(() => {
            this.getTPdata();
            this.getTPdatanames();
            this.getTroughputdatanames();
            setTimeout(() => {
              this.DrawChartTP();
            }, 300);
          
          }, 1200);

       
          setTimeout(() => {
            this.mergeData();
            setTimeout(() => {
              this.getGasAmbDrum();
              this.getGasAmbDrumnames();
              this.getDrumDataname();
              this.getAmbDataname();
            }, 100);
           
            setTimeout(() => {
              this.DrawChartGAD();
            }, 300);
          }, 1000);
        },
        (err) => {
          this.EnergyUnifiedData = [];
          console.log(err);
          
        }
      );
  }

  mergeData() {
    const mergedMap = new Map<string, any>();
    
    // Merge GasDrumData
    this.GasDrumData.forEach(dataArray => {
      dataArray.forEach(data => {
        mergedMap.set(data.obs_time_real, { ...mergedMap.get(data.obs_time_real), ...data });
      });
    });
    
    // Merge GasAmbData
    this.GasAmbData.forEach(dataArray => {
      dataArray.forEach(data => {
        mergedMap.set(data.obs_time_real, { ...mergedMap.get(data.obs_time_real), ...data });
      });
    });

    // Convert the Map back to an array
    this.mergedData = Array.from(mergedMap.values());
    // console.log("this.mergedData",     this.mergedData)
  }

  getGasAmbDrum(){
    this.GADdata = [];
    let FinalGAD = this.GasDrumData;
    for (let dataItemgad of FinalGAD) {
      this.GADdata.push(dataItemgad[0]);
    }
    // console.log("this.GADdata;", this.GADdata )
  }


  getGasAmbDrumnames(){
    this.GADIdnames = [];
    let finalgadData = this.GasDrumData;
    for (let dataItemgad of finalgadData) {
      let GADelements = Object.keys(dataItemgad[0]);
      for (let element of GADelements) {
        if (
          element != "obs_time_real" &&
          element != "NoMix" &&
          element != "drum_shell_temp" &&
          element != "date" &&
          element != "hourly" && 
          element != "ambient_temp"
        ) {
          if (!this.isItemPresentinListgc(this.GADIdnames, element)) {
            this.GADIdnames.push(element);
          }
        }
      }
    }
    // console.log("this.GADIdnames;", this.GADIdnames )
  }

  getDrumDataname(){
    this.DrumName = [];
    let FinalDrumdata = this.GasDrumData;
    for (let dataDrum of FinalDrumdata){
      let GADelements = Object.keys(dataDrum[0]);
      for (let element of GADelements) {
        if (
          element == "drum_shell_temp" 
        ) {
          if (!this.isItemPresentinListGAD(this.DrumName, element)) {
            this.DrumName.push(element);
          }
        }
      }
     
    }
    // console.log("this.DrumName;", this.DrumName )
  }

  getAmbDataname(){
    this.AmbName = [];
    let FinalAmbdata = this.GasAmbData;
    for (let dataAmb of FinalAmbdata){
      let GADelements = Object.keys(dataAmb[0]);
      for (let element of GADelements) {
        if (
          element === "ambient_temp" 
        ) {
          if (!this.isItemPresentinListGAD(this.AmbName, element)) {
            this.AmbName.push(element);
          }
        }
      }
    }
    // console.log("this.AmbName;", this.AmbName )
  }

  isItemPresentinListGAD(list1, item1) {
    for (let i = 0; i < list1.length; i++) {
      let listItem1 = list1[i];
      if (listItem1 === item1) {
        return true;
      }
    }
    return false;
  }


  getTPdata() {
    this.TPdata = [];
    let FinalTPData = this.ThroughputGas.final_data;
    for (let dataItemTP of FinalTPData) {
      this.TPdata.push(dataItemTP[0]);
    }
  }
  getTPdatanames() {
    this.TPIdNames = [];
    let FinalTPData = this.ThroughputGas.final_data;
    for (let dataItemTP of FinalTPData) {
      let TPelements = Object.keys(dataItemTP[0]);
      for (let element of TPelements) {
        if (
          element != "obs_time_real" &&
          element != "NoMix" &&
          element != "throughput" &&
          element != "date" &&
          element != "hourly"
        ) {
          if (!this.isItemPresentinListTP(this.TPIdNames, element)) {
            this.TPIdNames.push(element);
          }
        }
      }
    }
   
  }
  getTroughputdatanames() {
    this.TPName = [];
    let FinalTPnameData = this.ThroughputGas.final_data;
    for (let dataItemTP of FinalTPnameData) {
      let TPtonelements = Object.keys(dataItemTP[0]);
      for (let element of TPtonelements) {
        if (element == "throughput") {
          if (!this.isItemPresentinListgc(this.TPName, element)) {
            this.TPName.push(element);
          }
        }
      }
    }
   
  }
  isItemPresentinListTP(list1, item1) {
    for (let i = 0; i < list1.length; i++) {
      let listItem1 = list1[i];
      if (listItem1 === item1) {
        return true;
      }
    }
    return false;
  }


  getMixIdData() {
    this.mixIdData = [];
    let FinalData = this.Mixlistdata.final_data;
    for (let dataItem of FinalData) {
      this.mixIdData.push(dataItem[0]);
    }
  }
  getmixidnames() {
    this.MixIdNames = [];

    let FinalData = this.Mixlistdata.final_data;
    for (let dataItem of FinalData) {
      let Mixelements = Object.keys(dataItem[0]);

      for (let element of Mixelements) {
        if (
          element != "obs_time_real" &&
          element != "NoMix" &&
          element != "date" &&
          element != "hourly"
        ) {
          if (!this.isItemPresentinList(this.MixIdNames, element)) {
            this.MixIdNames.push(element);
          }
        }
      }
    }
  }
  isItemPresentinList(list, item) {
    for (let i = 0; i < list.length; i++) {
      let listItem = list[i];
      if (listItem === item) {
        return true;
      }
    }
    return false;
  }

  // Gas Consumption Api call

  getGCdata() {
    this.GCfdata = [];
    let FinalGCData = this.ProdEC.final_data;
    for (let dataItemgc of FinalGCData) {
      this.GCfdata.push(dataItemgc[0]);
    }
  }
  getGCdatanames() {
    this.GCIdNames = [];
    let FinalgcData = this.ProdEC.final_data;
    for (let dataItemgc of FinalgcData) {
      let GCelements = Object.keys(dataItemgc[0]);
      for (let element of GCelements) {
        if (
          element != "obs_time_real" &&
          element != "NoMix" &&
          element != "gas_per_ton" &&
          element != "date" &&
          element != "hourly"
        ) {
          if (!this.isItemPresentinListgc(this.GCIdNames, element)) {
            this.GCIdNames.push(element);
          }
        }
      }
    }
  }
  getGCtondatanames() {
    this.GCtonName = [];
    let FinalgctonData = this.ProdEC.final_data;
    for (let dataItemgc of FinalgctonData) {
      let GCtonelements = Object.keys(dataItemgc[0]);
      for (let element of GCtonelements) {
        if (element == "gas_per_ton") {
          if (!this.isItemPresentinListgc(this.GCtonName, element)) {
            this.GCtonName.push(element);
          }
        }
      }
    }
  }
  isItemPresentinListgc(list1, item1) {
    for (let i = 0; i < list1.length; i++) {
      let listItem1 = list1[i];
      if (listItem1 === item1) {
        return true;
      }
    }
    return false;
  }


  // Math.round(x)
  keysnValues() {
    Object.entries(this.data.Top3MixIdnames).forEach(([key, values]) => {
      this.keyValuePairs.push({ key, values });
    });
  }

  getunionAlert() {
    this.UnionAlertsData = this.data.Alertdata;
  }

  /* Popup Graphs */

  // Production vs Non production chart Full View (opeartion And Non Productive)
  DrawPvsNP() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartPNP", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 0;
      chart.paddingLeft = 15;
      // Add data
      chart.data = ref.data.graphData;
      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.title.text = "Date";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      categoryAxis.title.fontSize = 12;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.min = 0;
      valueAxis.marginRight = 25;
      valueAxis.title.text = "Hours (Hrs)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 10;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series

      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = "Productive";
      series.dataFields.valueY = "p_run_time";
      series.dataFields.categoryX = "obs_time_real";
      series.sequencedInterpolation = true;
      series.stroke = am4core.color("#55FF5C");
      // series.strokeWidth = 3;
      series.fill = am4core.color("#55FF5C");
      series.legendSettings.labelText = "[font-size:12px {stroke}]{name}[/]";
      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.name = "Non-Productive";
      series1.dataFields.valueY = "np_run_time";
      series1.dataFields.categoryX = "obs_time_real";
      series1.sequencedInterpolation = true;
      series1.legendSettings.labelText = "[font-size:12px {stroke}]{name}[/]";
      series1.stroke = am4core.color("#FFBF00");
      series1.fill = am4core.color("#FFBF00");
      // Make it stacked
      series.stacked = true;
      series1.stacked = true;
      // Configure columns
      series.columns.template.width = am4core.percent(30);
      series.tooltipText = " [font-size: 12px]Productive:{valueY.formatNumber('#.0')}(Hrs)";

      series1.columns.template.width = am4core.percent(30);
      series1.tooltipText = " [font-size: 12px]Non-Productive: {valueY.formatNumber('#.0')}(Hrs)";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      // labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      // Legend
      chart.legend = new am4charts.Legend();

      chart.legend.fontSize = 6;
    }, 150);
  }
  // Production vs Gas Vs Qnty chart Full View
  DrawProdGasQnty() {
    setTimeout(() => {
      let ref = this;

      am4core.useTheme(am4themes_animated);

      // Themes end

      // Create chart instance
      let chart = am4core.create("ProdHoursDiv1", am4charts.XYChart);
      chart.logo.disabled = true;

      var chartData = this.ProdHours;
      chart.data = chartData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis.maxPrecision = 0;
      valueAxis.title.text = "Productive (Hrs)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 35;
      valueAxis.cursorTooltipEnabled = false;

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Quantity (Tons)";
      valueAxis2.title.fontSize = "12px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.minGridDistance = 30;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");
      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis3.title.text = "Gas Consp PerTon(MMBTU)";
      valueAxis3.title.fontSize = "10px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis3.renderer.minGridDistance = 40;
      valueAxis3.renderer.grid.template.disabled = true;
      valueAxis3.renderer.labels.template.fontSize = 10;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      
      // Use a label function to customize label text
      valueAxis3.renderer.labels.template.adapter.add("text", (text, target) => {
        // Check if the text is a number and not NaN
        if (!isNaN(Number(text))) {
          if (Number(text) === 0) {
            return "0"; // Show "0" for zero values
          }
        }
        return text; // Use the default formatting for non-zero values
      });
      
      valueAxis3.numberFormatter = new am4core.NumberFormatter();
      valueAxis3.numberFormatter.numberFormat = "#.00"; // Default format
      
      
      // valueAxis3.numberFormatter = new am4core.NumberFormatter();
      // valueAxis3.numberFormatter.numberFormat = "#.0"; // Default format
      
      
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "p_run_time";
      columnSeries.dataFields.categoryX = "obs_time_real";
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 9px]{valueY}[/]";
      columnSeries.tooltipText = " [font-size: 12px] Productive:{valueY.formatNumber('#.0')}(Hrs)";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#55FF5C");
      columnSeries.columns.template.fill = am4core.color("#55FF5C");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "Productive (Hrs)";
      columnSeries.legendSettings.valueText =
        " [#55FF5C font-size: 10px] Productive[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#55FF5C");
      columnSeries.fill = am4core.color("#55FF5C");
      columnSeries.legendSettings.labelText = " [{stroke}{name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "mix_10";
      lineSeries.dataFields.categoryX = "obs_time_real";
      // lineSeries.tooltipText = "Quantity" + `:{${"mix_10"}}`;
      lineSeries.yAxis = valueAxis2;
      lineSeries.stroke = am4core.color("#32CD30");
      lineSeries.fill = am4core.color("#32CD30");
      lineSeries.strokeWidth = 3;
      lineSeries.tensionX = 0.9;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.tooltipText = "  [font-size: 12px]Quantity:{valueY.formatNumber('#.0')}(Tons)";
      lineSeries.name = "Quantity (Tons) ";
      lineSeries.legendSettings.valueText =
        "[#32CD30 font-size:10px] Quantity[/]";
      lineSeries.legendSettings.labelText = "[{stroke}{name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "act_qty_cng_perton";
      lineSeries1.dataFields.categoryX = "obs_time_real";
      // lineSeries1.tooltipText = "Energy" + `:{${"act_qty_cng_perton"}}`;
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#D6E865");
      lineSeries1.fill = am4core.color("#D6E865");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
        "  [font-size: 12px]GC perTon(MMBTU):{valueY.formatNumber('#.00')}";
      lineSeries1.name = "Gas Consumption (m3)";
      lineSeries1.legendSettings.valueText =
        "[#D6E865 font-size:10px ] Gas Consumption [/]";
      lineSeries1.legendSettings.labelText = "[{stroke}{name}][/]";
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#32CD30"); // tooltips grab fill from parent by default
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;

      circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 4;

      circle1.strokeWidth = 3;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

      var title = chart.titles.create();
      title.fill = am4core.color("#FFF");
      var resetLabel = chart.plotContainer.createChild(am4core.Label);
      resetLabel.text = "[bold]<< Back[/]";
      resetLabel.fill = am4core.color("#ffffff");
      resetLabel.x = 20;
      resetLabel.y = -30;
      resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      resetLabel.hide();
      resetLabel.events.on("hit", function (ev) {
        resetLabel.hide();
        title.hide();
        // ev.target.baseSprite.titles.getIndex(0).text = "Throughput";
        chart.data = chartData;
      });
      interface DataContext {
        hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
        obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
        // Add other properties if necessary
      }
      columnSeries.columns.template.events.on(
        "hit",
        function (ev) {
          const dataContext = ev.target.dataItem.dataContext as DataContext; // Define the type of dataContext explicitly
          if (Array.isArray(dataContext.hourly)) {
            // update the chart title
            chart.titles.getIndex(0).text =
              " Hourly Data " + " " + "-" + " " + dataContext.obs_time_real;
            // set the hourly data for the clicked Day
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
          }
        },
        this
      );
      lineSeries.events.on(
        "hit",
        function (ev) {
          const dataContext = ev.target.dataItem.dataContext as DataContext; // Define the type of dataContext explicitly
          if (Array.isArray(dataContext.hourly)) {
            // update the chart title
            chart.titles.getIndex(0).text = dataContext.obs_time_real;
            // set the hourly data for the clicked Day
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
          }
        },
        this
      );
      lineSeries1.events.on(
        "hit",
        function (ev) {
          const dataContext = ev.target.dataItem.dataContext as DataContext; // Define the type of dataContext explicitly
          if (Array.isArray(dataContext.hourly)) {
            // update the chart title
            chart.titles.getIndex(0).text = dataContext.obs_time_real;
            // set the hourly data for the clicked Day
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
          }
        },
        this
      );

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      // chart.cursor.yAxis.disabled = true;
    }, 100);
  }
  // OEE chart Full View
  drawProdOEEchart() {
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
      // Themes end
      // Create chart instance
      var chart = am4core.create("OEEindex", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      chart.marginRight = 15;
      chart.paddingLeft = 10;
      chart.marginLeft = 10;

      var chartData = this.ProdOEE;

      chart.data = chartData;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.marginRight = 30;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.title.text = "OEE(%)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "oee";
      series.dataFields.categoryX = "obs_time_real";
      series.name = "OEE 1";
      series.tooltipText = " [font-size: 12px]{name}: {valueY.formatNumber('#.')}(%)[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.propertyFields.url = "url";
      series.strokeWidth = 3;
      series.stroke = am4core.color("#55FF5C");
      series.fill = am4core.color("#55FF5C");
      series.legendSettings.labelText = "[{stroke}]{name}[/]";
      var series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = "oee2";
      series1.dataFields.categoryX = "obs_time_real";
      series1.name = "OEE 2";
      series1.tooltipText = " [font-size: 12px]{name}: {valueY.formatNumber('#.')}(%)[/]";
      series1.columns.template.width = am4core.percent(30);
      series1.columns.template.propertyFields.url = "url";
      series1.stroke = am4core.color("#D6E865");
      series1.fill = am4core.color("#D6E865");
      series1.legendSettings.labelText = "[{stroke}]{name}[/]";
      var title = chart.titles.create();
      title.fill = am4core.color("#FFF");
      var resetLabel = chart.plotContainer.createChild(am4core.Label);
      resetLabel.text = "[bold]<< Back[/]";
      resetLabel.fill = am4core.color("#ffffff");
      resetLabel.x = 20;
      resetLabel.y = -20;
      resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      resetLabel.hide();
      resetLabel.events.on(
        "hit",
        function (ev) {
          resetLabel.hide();
          title.hide();
          // ev.target.baseSprite.titles.getIndex(0).text = "Throughput";
          chart.data = chartData;
          this.IsDropdownTrue = true;
        },
        this
      );

      interface DataContext {
        hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
        obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
        // Add other properties if necessary
      }

      series.columns.template.events.on(
        "hit",
        function (ev) {
          const dataContext = ev.target.dataItem.dataContext as DataContext; // Define the type of dataContext explicitly
          if (Array.isArray(dataContext.hourly)) {
            // update the chart title
            chart.titles.getIndex(0).text =
              " Hourly OEE " + " " + "-" + " " + dataContext.obs_time_real;
            // set the hourly data for the clicked Day
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
            this.IsDropdownTrue = false;
          }
        },
        this
      );

      series1.columns.template.events.on(
        "hit",
        function (ev) {
          const dataContext = ev.target.dataItem.dataContext as DataContext; // Define the type of dataContext explicitly
          if (Array.isArray(dataContext.hourly)) {
            // update the chart title
            chart.titles.getIndex(0).text =
              " Hourly OEE " + " " + "-" + " " + dataContext.obs_time_real;
            // set the hourly data for the clicked Day
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
            this.IsDropdownTrue = false;
          }
        },
        this
      );
    }, 100);
  }
  // Throughput chart Full View
  drawProdTPchart() {
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
      // Themes end
      // Create chart instance
      var chart = am4core.create("TPgraph", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      chart.marginRight = 15;
      chart.paddingLeft = 10;
      chart.marginLeft = 10;
      var chartData = this.ProdTP;
      chart.data = chartData;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.marginRight = 30;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.title.text = "Throughput (TPH)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "throughput";
      series.dataFields.categoryX = "obs_time_real";
      series.name = "Throughput";
      series.columns.template.tooltipText =
        " [font-size: 12px]{name}: {valueY.formatNumber('#.0')}(TPH)[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.propertyFields.url = "url";
      series.strokeWidth = 3;
      series.stroke = am4core.color("#55FF5C");
      series.fill = am4core.color("#55FF5C");
      series.legendSettings.labelText = "[{stroke}]{name}[/]";

      var title = chart.titles.create();
      // title.text = "Throughput";
      title.fill = am4core.color("#FFF");
      var resetLabel = chart.plotContainer.createChild(am4core.Label);
      resetLabel.text = "[bold]<< Back[/]";
      resetLabel.fill = am4core.color("#ffffff");
      resetLabel.x = 20;
      resetLabel.y = -20;
      resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      resetLabel.hide();

      resetLabel.events.on(
        "hit",
        function (ev) {
          resetLabel.hide();
          title.hide();
          chart.data = chartData;
          this.IsDropdownTrue = true;
        },
        this
      );
      interface DataContext {
        hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
        obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
        // Add other properties if necessary
      }

      series.columns.template.events.on(
        "hit",
        function (ev) {
          const dataContext = ev.target.dataItem.dataContext as DataContext; // Define the type of dataContext explicitly
          if (Array.isArray(dataContext.hourly)) {
            // update the chart title
            chart.titles.getIndex(0).text =
              " Hourly Throughput" +
              " " +
              "-" +
              " " +
              dataContext.obs_time_real;
            // set the hourly data for the clicked Day
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();

            this.IsDropdownTrue = false;
          }
        },
        this
      );
    }, 100);
  }
  // Utilization Losses chart Full View
  drawProdlosschart() {
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
      // Themes end
      // Create chart instance
      var chart = am4core.create("ProdlossgraphD", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      chart.marginRight = 15;
      chart.paddingLeft = 10;
      chart.marginLeft = 10;
      var chartData = this.ProdLoss;
      chart.data = chartData;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.marginRight = 30;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.title.text = "Losses (Tons)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "losses";
      series.dataFields.categoryX = "obs_time_real";
      series.name = "Utilization Losses ";
      series.columns.template.tooltipText =
        " [font-size: 12px]{name}: {valueY.formatNumber('#.')}(TPH)[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.propertyFields.url = "url";
      series.strokeWidth = 3;
      series.stroke = am4core.color("#c7152a");
      series.fill = am4core.color("#c7152a");
      series.legendSettings.labelText = "[{stroke}]{name}[/]";

      var title = chart.titles.create();
      // title.text = "Throughput";
      title.fill = am4core.color("#FFF");
      var resetLabel = chart.plotContainer.createChild(am4core.Label);
      resetLabel.text = "[bold]<< Back[/]";
      resetLabel.fill = am4core.color("#ffffff");
      resetLabel.x = -30;
      resetLabel.y = -30;
      resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      resetLabel.hide();
      resetLabel.events.on(
        "hit",
        function (ev) {
          resetLabel.hide();
          title.hide();
          // ev.target.baseSprite.titles.getIndex(0).text = "Throughput";
          chart.data = chartData;
          this.IsDropdownTrue = true;
        },
        this
      );
      interface DataContext {
        hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
        obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
        // Add other properties if necessary
      }

      series.columns.template.events.on(
        "hit",
        function (ev) {
          const dataContext = ev.target.dataItem.dataContext as DataContext; // Define the type of dataContext explicitly
          if (Array.isArray(dataContext.hourly)) {
            // update the chart title
            chart.titles.getIndex(0).text =
              " Hourly Losses" + " " + "-" + " " + dataContext.obs_time_real;
            // set the hourly data for the clicked Day
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
            this.IsDropdownTrue = false;
          }
        },
        this
      );
    }, 100);
  }
  // Gas Consumption chart Full View ECgraph
  DrawChartGC() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("ECgraph", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
    
      // Add data
      var chartData = this.GCfdata;
      chart.data = chartData;
    
      chart.colors.list = [
        am4core.color("#00ff00"),
        am4core.color("#03c03c"),
        am4core.color("#2e8b57"),
        am4core.color("#3fff00"),
        am4core.color("#059033"),
        am4core.color("#009900"),
        am4core.color("#008000"),
        am4core.color("#195905"),
        am4core.color("#8fd400"),
        am4core.color("#76ff7a"),
        am4core.color("#50c878"),
        am4core.color("#00ff7f"),
      ];
    
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
    
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      chart.cursor.behavior = "none";
    
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.marginRight = 50;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.title.text = "Gas Consumption(MMBTU)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.minGridDistance = 45;
      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      valueAxis2.title.text = "Gas Consumption (Per Ton)";
      valueAxis2.title.fontSize = "10px";
      valueAxis2.title.fill = am4core.color("#FFF");
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      valueAxis2.min = 0;
      // valueAxis2.max= 0.5;
      valueAxis2.syncWithAxis = valueAxis;
      valueAxis2.renderer.labels.template.fontSize = 12;
      valueAxis2.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis2.renderer.minGridDistance = 45;


            // Use a label function to customize label text
            valueAxis2.renderer.labels.template.adapter.add("text", (text, target) => {
              // Check if the text is a number and not NaN
              if (!isNaN(Number(text))) {
                if (Number(text) === 0) {
                  return "0"; // Show "0" for zero values
                }
              }
              return text; // Use the default formatting for non-zero values
            });
            
            valueAxis2.numberFormatter = new am4core.NumberFormatter();
            valueAxis2.numberFormatter.numberFormat = "#.00"; // Default format
            
            
      var title = chart.titles.create();
      title.fill = am4core.color("#FFF");
      var resetLabel = chart.plotContainer.createChild(am4core.Label);
      resetLabel.text = "[bold]<< Back[/]";
      resetLabel.fill = am4core.color("#ffffff");
      resetLabel.x = -30;
      resetLabel.y = -30;
      resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      resetLabel.hide();
      resetLabel.events.on(
        "hit",
        function (ev) {
          resetLabel.hide();
          title.hide();
    
          chart.data = chartData;
        },
        this
      );
    
      interface DataContext {
        hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
        obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
        // Add other properties if necessary
      }
    
      // Create series
      function createSeries(field, name) {
        // Set up series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "obs_time_real";
        series.sequencedInterpolation = true;
        series.stacked = true;
        series.strokeWidth = 1;
        series.legendSettings.labelText = " [{stroke} {name}][/]";
        series.legendSettings.valueText = "[#fff font-size:12px ] {name}[/]";
    
        // Configure columns
        series.columns.template.width = am4core.percent(40);
        // series.tooltipText = "{name}\n{valueY.formatNumber('#.')}";
        series.tooltipText =
          " [font-size: 12px]{name}\n{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
        return series;
      }
      for (let i = 0; i < this.GCIdNames.length; i++) {
        let GCMixIdName = this.GCIdNames[i];
        let series = createSeries(GCMixIdName, GCMixIdName);
        series.columns.template.events.on("hit", (ev) => {
          const dataContext = ev.target.dataItem.dataContext as DataContext;
          if (Array.isArray(dataContext.hourly)) {
            chart.titles.getIndex(0).text =
              " Hourly MixID " + " " + "-" + " " + dataContext.obs_time_real;
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
          }
        });
      }
    
      function createSeries1(field, name) {
        // Set up series
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.name = name;
        series1.dataFields.valueY = field;
        series1.dataFields.categoryX = "obs_time_real";
        series1.yAxis = valueAxis2;
        series1.stroke = am4core.color("#D6E865");
        series1.fill = am4core.color("#D6E865");
    
        series1.tensionX = 0.9;
        series1.propertyFields.strokeDasharray = "lineDash";
        series1.strokeWidth = 3;
        series1.legendSettings.labelText = " [{stroke} {name}][/]";
        series1.legendSettings.valueText =
          "[#fff font-size:12px ] Gas Consumption (per Ton)[/]";
    
        // Configure columns
        series1.tooltipText = " [font-size: 12px]GC :{valueY.formatNumber('#.00')}(per Ton)";
        let bullet1 = series1.bullets.push(new am4charts.Bullet());
        bullet1.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
        let circle1 = bullet1.createChild(am4core.Circle);
        circle1.radius = 4;
        circle1.strokeWidth = 3;
        return series1;
      }
      for (let i = 0; i < this.GCtonName.length; i++) {
        let GCMixtonIdName = this.GCtonName[i];
        createSeries1(GCMixtonIdName, GCMixtonIdName);
      }
    }, 150);
  }
  // Non Productive vs Gas Consumption chart Full View
  DrawNonProdGasQnty() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end
      // Create chart instance
      let chart = am4core.create("NPDiv", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.data = this.ProdHours;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // dateAxis.baseInterval = { timeUnit: "day", count: 1 },
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 40;
      dateAxis.renderer.labels.template.fontSize = 12;
      dateAxis.title.fill = am4core.color("#FFF");
      dateAxis.renderer.labels.template.fill = am4core.color("#fff");

      // /* Create axes */
      // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      // categoryAxis.dataFields.category = "obs_time_real";
      // categoryAxis.renderer.minGridDistance = 100;

      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.maxPrecision = 0;
      valueAxis.title.text = "Non Productive (Hrs)";
      valueAxis.title.fontSize = "12px";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;
      // let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis2.maxPrecision = 0;
      // valueAxis2.title.text = "Quantity (Tons)";
      // valueAxis2.title.fontSize = "12px";
      // valueAxis2.title.fill = am4core.color("#FFF");
      // valueAxis2.cursorTooltipEnabled = false;
      // valueAxis2.renderer.opposite = true;
      // valueAxis2.min = 0;
      // valueAxis2.syncWithAxis = valueAxis;
      // valueAxis2.renderer.labels.template.fontSize = 12;
      // valueAxis2.renderer.labels.template.fill = am4core.color("#fff");

      let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis3.maxPrecision = 0;
      valueAxis3.title.text = "Gas Consumption (MMBTU)";
      valueAxis3.title.fontSize = "12px";
      valueAxis3.title.fill = am4core.color("#FFF");
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.renderer.opposite = true;
      valueAxis3.min = 0;
      valueAxis3.syncWithAxis = valueAxis;
      valueAxis3.renderer.labels.template.fontSize = 12;
      valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());

      columnSeries.dataFields.valueY = "np_run_time";
      columnSeries.dataFields.dateX = "obs_time_real";
      // columnSeries.stroke = am4core.color("#10C8F8");

      columnSeries.tooltipText = " [font-size: 12px]Non Productive" + `:{${"np_run_time"}}(Hrs)`;
      columnSeries.legendSettings.labelText = "[{stroke}]{name}[/]";
      // columnSeries.columns.template.tooltipText =
      //   "[#fff font-size: 9px]{valueY}[/]";
      columnSeries.tooltipText =
        " [font-size: 12px] Non Productive :{valueY.formatNumber('#.0')}";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.stroke = am4core.color("#FFBF00");
      columnSeries.columns.template.fill = am4core.color("#FFBF00");
      // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.name = "Non Productive ";
      columnSeries.legendSettings.valueText =
        " [#FFBF00 font-size: 9px] {name}[/]";
      columnSeries.strokeWidth = 3;
      // series.tensionX = 0.9;
      columnSeries.stroke = am4core.color("#55FF5C");
      columnSeries.fill = am4core.color("#55FF5C");
      columnSeries.legendSettings.labelText = " [{stroke} {name}][/]";
      columnSeries.columns.template.width = am4core.percent(30);

      // lineSeries.name = "Incidents";
      // let lineSeries = chart.series.push(new am4charts.LineSeries());
      // lineSeries.dataFields.valueY = "mix_10";
      // lineSeries.dataFields.dateX = "obs_time_real";
      // lineSeries.tooltipText = "Quantity" + `:{${"mix_10"}}`;
      // lineSeries.yAxis = valueAxis2;
      // lineSeries.stroke = am4core.color("#D6E865");
      // lineSeries.fill = am4core.color("#D6E865");
      // lineSeries.strokeWidth = 3;
      // lineSeries.tensionX = 0.9;
      // lineSeries.propertyFields.strokeDasharray = "lineDash";
      // lineSeries.tooltip.label.textAlign = "middle";
      // lineSeries.tooltipText = " {name}:{valueY.formatNumber('#.')}";
      // lineSeries.name = "Quantity (Tons) ";
      // lineSeries.legendSettings.valueText =
      //   "[#D6E865 font-size:10px font-weight:bold] {name}[/]";
      // lineSeries.legendSettings.labelText = "[{stroke} {name}][/]";
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.dataFields.valueY = "act_qty_cng";
      lineSeries1.dataFields.dateX = "obs_time_real";
      // lineSeries1.tooltipText = "Gas consumption" + `:{${"act_qty_cng_perton"}}`;
      // FFBF00
      lineSeries1.yAxis = valueAxis3;
      lineSeries1.stroke = am4core.color("#55FF5C");
      lineSeries1.fill = am4core.color("#55FF5C");
      lineSeries1.strokeWidth = 3;
      lineSeries1.tensionX = 0.9;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.tooltipText =
        " [font-size: 12px] Gas Consumption:{valueY.formatNumber('#.00')}(MMBTU)";
      lineSeries1.name = "Gas Consumption ";
      lineSeries1.legendSettings.valueText =
        "[#55FF5C font-size: 9px] {name}[/]";
      lineSeries1.legendSettings.labelText = "[{stroke} {name}][/]";

      // let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      // bullet.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
      // bullet.tooltipText = "[#fff font-size: 12px]{valueY}[/]";
      // bullet.tooltipText =" {name}:{valueY.formatNumber('#.')}";
      // let circle = bullet.createChild(am4core.Circle);
      // circle.radius = 4;
      // // circle.fill = am4core.color("");
      // circle.strokeWidth = 3;
      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#55FF5C"); // tooltips grab fill from parent by default
      // bullet1.tooltipText = "[#fff font-size: 12px]{valueY}[/]";
      // bullet1.tooltipText =" {name}:{valueY.formatNumber('#.')}";
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 4;
      // circle.fill = am4core.color("");
      circle1.strokeWidth = 3;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      // chart.legend.fill = am4core.color("#fff");

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = dateAxis;
      chart.cursor.lineY.disabled = true;
      // // chart.cursor.yAxis.disabled = true;
      chart.numberFormatter.numberFormat = "#";
      // chart.axis.cursorTooltipEnabled = false;
      // chart.cursor.xAxis.toolTipEnabled=false

      // chart.interpolationDuration = 50000;
    }, 100);
  }
  // Non Productive -- Gas Consumption chart Full View
  DrawChartGCNP() {
    setTimeout(() => {

      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("ECgraph1", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;

      // Add data
      chart.data = this.GCfdata;

      chart.colors.list = [
        am4core.color("#00ff00"),
        am4core.color("#03c03c"),
        am4core.color("#2e8b57"),
        am4core.color("#3fff00"),
        am4core.color("#059033"),
        am4core.color("#009900"),
        am4core.color("#008000"),
        am4core.color("#195905"),
        am4core.color("#8fd400"),
        am4core.color("#76ff7a"),
        am4core.color("#50c878"),
        am4core.color("#00ff7f"),
      ];

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.marginRight = 50;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.title.text = "Gas Consumption(MMBTU)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.cursorTooltipEnabled = false;
      // Create series
      function createSeries(field, name) {
        // Set up series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "obs_time_real";
        series.sequencedInterpolation = true;
        series.stacked = true;
        series.strokeWidth = 1;
        // series.name = name;
        // series.dataFields.value = field;
        // series.dataFields.valueY = field + "_end";
        // series.dataFields.openValueY = field + "_start";
        // series.dataFields.categoryX = "obs_time_real";
        // series.sequencedInterpolation = true;
        // series.clustered = false;

        // Configure columns
        series.columns.template.width = am4core.percent(70);
        series.tooltipText = " [font-size: 12px]Gas Consumption :{valueY.formatNumber('#.')}";

        // Add label
        // let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        // labelBullet.label.text = "{valueY.formatNumber('#.0')}";
        // labelBullet.locationY = 0.5;
        // labelBullet.label.fontSize = "8px";
        // labelBullet.label.hideOversized = true;
        return series;
      }

      for (let i = 0; i < this.data.graphGCNames.length; i++) {
        let GCMixIdName = this.data.graphGCNames[i];
        createSeries(GCMixIdName, GCMixIdName);
      }

      // Legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.fill = am4core.color("#fff");
      // chart.cursor = new am4charts.XYCursor();
      // chart.cursor.xAxis = categoryAxis;
      // chart.cursor.lineY.disabled = true;
    }, 150);
  }
  // Mix Tons Run chart Full View
  DrawMixID() {
    setTimeout(() => {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdiv2", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;

      // Add data
      var chartData = this.mixIdData;
      chart.data = chartData;

      chart.colors.list = [
        am4core.color("#00ff00"),
        am4core.color("#03c03c"),
        am4core.color("#2e8b57"),
        am4core.color("#3fff00"),
        am4core.color("#059033"),
        am4core.color("#009900"),
        am4core.color("#008000"),
        am4core.color("#195905"),
        am4core.color("#8fd400"),
        am4core.color("#76ff7a"),
        am4core.color("#50c878"),
        am4core.color("#00ff7f"),
      ];

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.cursor.lineY.disabled = true;
      chart.cursor.behavior = "none";

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.marginRight = 45;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.min = 0;
      valueAxis.title.text = "Total Mix Ton";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontSize = 12;
      valueAxis.cursorTooltipEnabled = false;

      var title = chart.titles.create();
      title.fill = am4core.color("#FFF");
      var resetLabel = chart.plotContainer.createChild(am4core.Label);
      resetLabel.text = "[bold]<< Back[/]";
      resetLabel.fill = am4core.color("#ffffff");
      resetLabel.x = -30;
      resetLabel.y = -30;
      resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      resetLabel.hide();
      resetLabel.events.on(
        "hit",
        function (ev) {
          resetLabel.hide();
          title.hide();
          this.IsDropdownTrue = true;
          chart.data = chartData;
        },
        this
      );

      interface DataContext {
        hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
        obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
        // Add other properties if necessary
      }
      function createSeries(field, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "obs_time_real";
        series.name = name;
        series.stacked = true;
        series.strokeWidth = 1;
        series.sequencedInterpolation = true;
        series.legendSettings.labelText = " [{stroke} {name}][/]";
        series.legendSettings.valueText =
          "[#fff font-size:12px font-weight:bold] {name}[/]";
        series.columns.template.width = am4core.percent(70);
        series.tooltipText = " [font-size: 12px]{name}\n{valueY.formatNumber('#.0')} Tons";
        // Customize other properties of the series as needed

        return series;
      }
      for (let i = 0; i < this.MixIdNames.length; i++) {
        let MixIdName = this.MixIdNames[i];
        let series = createSeries(MixIdName, MixIdName); // Create and capture the series variable
        series.columns.template.events.on("hit", (ev) => {
          const dataContext = ev.target.dataItem.dataContext as DataContext;
          if (Array.isArray(dataContext.hourly)) {
            chart.titles.getIndex(0).text =
              " Hourly MixID " + " " + "-" + " " + dataContext.obs_time_real;
            chart.data = dataContext.hourly;
            resetLabel.show();
            title.show();
            this.IsDropdownTrue = false;
          }
        });
      }
    }, 150);
  }
  // Production Efficiency chart Full View
  drawProductionchart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("proddiv", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      chart.numberFormatter.numberFormat = "#";
      // chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = this.ProdEff;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.title.text = "Date";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.numberFormatter.numberFormat = "#.0";
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.fill = am4core.color("#fff");
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.title.text = "Production Efficiency(%)";
      valueAxis.title.fill = am4core.color("#FFF");
      //  valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "production_efficiency";
      series.dataFields.categoryX = "obs_time_real";
      series.tooltipText = " [font-size: 12px] {name}:{valueY.formatNumber('#.')}(%)";
      // series.fill = am4core.color("#55FF5C");

      // series.fill = am4core.color("#55FF5C");
      series.strokeWidth = 3;
      series.tooltip.label.propertyFields.fill = "#fff";
      series.name = "Production Efficiency ";
      series.legendSettings.labelText = "[{stroke}]{name}[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.fill = am4core.color("#55FF5C");
      series.columns.template.stroke = am4core.color("#0eab73");
      series.columns.template.adapter.add("stroke", function (stroke, target) {
        if (
          target.dataItem &&
          target.dataItem.values.valueY.value >= 0 &&
          target.dataItem.values.valueY.value <= 90
        ) {
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          return am4core.color("#e5b027");
        } else {
          return am4core.color("#0eab73");
        }
      });
      series.columns.template.adapter.add("fill", function (fill, target) {
        if (
          target.dataItem &&
          target.dataItem.values.valueY.value >= 0 &&
          target.dataItem.values.valueY.value <= 90
        ) {
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          return am4core.color("#e5b027");
        } else {
          return am4core.color("#0eab73");
        }
      });
      categoryAxis.title.fontSize = 12;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;
      chart.responsive.enabled = false;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 150);
  }
  // Quality Efficiency chart Full View
  drawQualityeffncyChart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("qualdrum1", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;

      // Add data
      chart.data = this.QualEff;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.title.text = "Date";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.numberFormatter.numberFormat = "#.";
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.title.text = "Quality Efficiency (%)";
      valueAxis.title.fill = am4core.color("#FFF");
      //  valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // valueAxis.renderer.minGridDistance = 50;
      // valueAxis.numberFormatter.numberFormat = "#.0";
      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "quality_eff";
      series.dataFields.categoryX = "obs_time_real";
      // series.tooltipText = "Quality Efficiency" + `:{${"quality_eff"}}`;
      series.tooltipText = " [font-size: 12px] {name}:{valueY.formatNumber('#.')}(%)";
      series.strokeWidth = 3;
      // series.tensionX = 0.9;
      series.stroke = am4core.color("#55FF5C");
      // series.fill = am4core.color("#55FF5C");
      // series.tooltip.label.propertyFields.fill = "#fff";
      // series.legendSettings.valueText = "qual_ac_dev [bold][/]";
      // series.label.text ="{valueY}";
      series.name = " Quality Efficiency ";
      series.legendSettings.labelText = "[{stroke}]{name}[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.fill = am4core.color("#55FF5C");
      series.columns.template.stroke = am4core.color("#0eab73");
      series.columns.template.adapter.add("stroke", function (stroke, target) {
        if (
          target.dataItem &&
          target.dataItem.values.valueY.value >= 0 &&
          target.dataItem.values.valueY.value <= 90
        ) {
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          return am4core.color("#e5b027");
        } else {
          return am4core.color("#0eab73");
        }
      });
      series.columns.template.adapter.add("fill", function (fill, target) {
        if (target.dataItem && target.dataItem.values.valueY.value <= 90) {
          //  series.stroke = am4core.color("#c7152a");
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          // // series.stroke = am4core.color("#e5b027");
          return am4core.color("#e5b027");
        } else {
          //    series.stroke = am4core.color("#0eab73");
          return am4core.color("#0eab73");
        }
      });
      categoryAxis.title.fontSize = 12;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

      //Add scrollbar
      // chart.scrollbarX = new am4core.Scrollbar();
      // chart.scrollbarX.parent = chart.topAxesContainer;
      // chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      // chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      // chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      // chart.scrollbarX.minHeight = 1;
      // Applied on hover
      // chart.scrollbarX.startGrip.background.states.getKey(
      //   "hover"
      // ).properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.endGrip.background.states.getKey(
      //   "hover"
      // ).properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
      //   am4core.color("rgb(64, 60, 60)");
      // // Applied on mouse down
      // chart.scrollbarX.startGrip.background.states.getKey(
      //   "down"
      // ).properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.endGrip.background.states.getKey(
      //   "down"
      // ).properties.fill = am4core.color("rgb(64, 60, 60)");
      // chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
      //   am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;
      //  chart.responsive.enabled = false;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 150);
  }
  // Energy Efficiency chart Full View (opeartion And Non Productive)
  drawEnergyeffncyChart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("ACReal", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      // chart.numberFormatter.numberFormat = "#";

      // Add data
      chart.data = this.EnergyEff;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.title.text = "Date";
      categoryAxis.title.fill = am4core.color("#FFF");

      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.title.text = "Energy Efficiency  (%) ";
      valueAxis.title.fill = am4core.color("#FFF");
      //  valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // valueAxis.numberFormatter.numberFormat = "#.0";
      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "energy_eff";
      series.dataFields.categoryX = "obs_time_real";
      // series.tooltipText = " Energy Efficiency" + `:{${"energy_eff"}}`;
      series.tooltipText = " [font-size: 12px] {name}:{valueY.formatNumber('#.')}(%)";
      series.strokeWidth = 3;
      // series.stroke = am4core.color("#55FF5C");
      // series.fill = am4core.color("#55FF5C");
      series.tooltip.label.propertyFields.fill = "#fff";
      // series.legendSettings.valueText = "Performance Efficiency [bold][/]";
      series.name = "Energy Efficiency ";
      series.legendSettings.labelText = "[{stroke}]{name}[/]";
      // series.label.text ="{valueY}";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.fill = am4core.color("#55FF5C");
      series.columns.template.stroke = am4core.color("#0eab73");
      series.columns.template.adapter.add("stroke", function (stroke, target) {
        if (
          target.dataItem &&
          target.dataItem.values.valueY.value >= 0 &&
          target.dataItem.values.valueY.value <= 90
        ) {
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          return am4core.color("#e5b027");
        } else {
          return am4core.color("#0eab73");
        }
      });
      series.columns.template.adapter.add("fill", function (fill, target) {
        if (target.dataItem && target.dataItem.values.valueY.value <= 90) {
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          return am4core.color("#e5b027");
        } else {
          return am4core.color("#0eab73");
        }
      });
      categoryAxis.title.fontSize = 12;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;
      chart.responsive.enabled = false;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 150);
  }
  // Carbon Index   chart Full View (opeartion And Non Productive)
  drawCarbonIndexchart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("Carbonindex", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 15;
      chart.numberFormatter.numberFormat = "#";
      // chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data =  this.CarbonEff ;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time_real";
      categoryAxis.title.text = "Date";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.title.text = "Carbon Efficiency  (%) ";
      valueAxis.title.fill = am4core.color("#FFF");
      //  valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.cursorTooltipEnabled = false;
      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "carbon_eff";
      series.dataFields.categoryX = "obs_time_real";
      // series.tooltipText = "Carbon Index" + `:{${"carbon_index"}}`;
      series.tooltipText = "  [font-size: 12px]{name}:{valueY.formatNumber('#.')}(%)";
      series.strokeWidth = 3;
      // series.tensionX = 0.9;
      // series.stroke = am4core.color("#55FF5C");
      // series.fill = am4core.color("#55FF5C");

      series.name = " Carbon Efficiency ";
      series.legendSettings.labelText = "[{stroke}]{name}[/]";
      series.columns.template.width = am4core.percent(30);
      series.columns.template.fill = am4core.color("#55FF5C");
      series.columns.template.stroke = am4core.color("#0eab73");
      series.columns.template.adapter.add("stroke", function (stroke, target) {
        if (
          target.dataItem &&
          target.dataItem.values.valueY.value >= 0 &&
          target.dataItem.values.valueY.value <= 90
        ) {
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          return am4core.color("#e5b027");
        } else {
          return am4core.color("#0eab73");
        }
      });
      series.columns.template.adapter.add("fill", function (fill, target) {
        if (target.dataItem && target.dataItem.values.valueY.value <= 90) {
          return am4core.color("#c7152a");
        } else if (
          target.dataItem &&
          target.dataItem.values.valueY.value > 90 &&
          target.dataItem &&
          target.dataItem.values.valueY.value <= 97
        ) {
          return am4core.color("#e5b027");
        } else {
          return am4core.color("#0eab73");
        }
      });
      categoryAxis.title.fontSize = 12;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "bottom";

      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;
      //  chart.responsive.enabled = false;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 150);
  }

    // Gas Consumption - Throughput Productive Graph Funtion 
    DrawChartTP() {
      setTimeout(() => {
        let ref = this;
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create("chartGTP1", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.paddingRight = 15;
  
        // Add data
        var chartData = this.TPdata;
        chart.data = chartData;
  
        chart.colors.list = [
          am4core.color("#00ff00"),
          am4core.color("#03c03c"),
          am4core.color("#2e8b57"),
          am4core.color("#3fff00"),
          am4core.color("#059033"),
          am4core.color("#009900"),
          am4core.color("#008000"),
          am4core.color("#195905"),
          am4core.color("#8fd400"),
          am4core.color("#76ff7a"),
          am4core.color("#50c878"),
          am4core.color("#00ff7f"),
        ];
  
        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "obs_time_real";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 50;
        categoryAxis.numberFormatter.numberFormat = "#";
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.title.fontSize = 12;
        categoryAxis.title.fill = am4core.color("#FFF");
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
  
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = categoryAxis;
        chart.cursor.lineY.disabled = true;
        chart.cursor.behavior = "none";
  
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.marginRight = 50;
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");
        valueAxis.min = 0;
        valueAxis.title.text = "Gas Consumption(MMBTU)";
        valueAxis.title.fill = am4core.color("#FFF");
        valueAxis.title.fontSize = 12;
        valueAxis.cursorTooltipEnabled = false;
  
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.maxPrecision = 0;
        valueAxis2.title.text = "Throughput (TPH)";
        valueAxis2.title.fontSize = "12px";
        valueAxis2.title.fill = am4core.color("#FFF");
        valueAxis2.cursorTooltipEnabled = false;
        valueAxis2.renderer.opposite = true;
        valueAxis2.min = 0;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.renderer.labels.template.fontSize = 12;
        valueAxis2.renderer.labels.template.fill = am4core.color("#fff");
  
        var title = chart.titles.create();
        title.fill = am4core.color("#FFF");
        var resetLabel = chart.plotContainer.createChild(am4core.Label);
        resetLabel.text = "[bold]<< Back[/]";
        resetLabel.fill = am4core.color("#ffffff");
        resetLabel.x = -30;
        resetLabel.y = -30;
        resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        resetLabel.hide();
        resetLabel.events.on(
          "hit",
          function (ev) {
            resetLabel.hide();
            title.hide();
  
            chart.data = chartData;
          },
          this
        );
  
        interface DataContext {
          hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
          obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
          // Add other properties if necessary
        }
  
        // Create series
        function createSeries(field, name) {
          // Set up series
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.name = name;
          series.dataFields.valueY = field;
          series.dataFields.categoryX = "obs_time_real";
          series.sequencedInterpolation = true;
          series.stacked = true;
          series.strokeWidth = 1;
          series.legendSettings.labelText = " [{stroke} {name}][/]";
          series.legendSettings.valueText = "[#fff font-size:12px ] {name}[/]";
  
          // Configure columns
          series.columns.template.width = am4core.percent(40);
          // series.tooltipText = "{name}\n{valueY.formatNumber('#.')}";
          series.tooltipText =
            " [font-size: 12px]{name}\n{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
          return series;
        }
        for (let i = 0; i < this.TPIdNames.length; i++) {
          let TPIdName = this.TPIdNames[i];
          let series = createSeries(TPIdName, TPIdName);
          series.columns.template.events.on("hit", (ev) => {
            const dataContext = ev.target.dataItem.dataContext as DataContext;
            if (Array.isArray(dataContext.hourly)) {
              chart.titles.getIndex(0).text =
                " Hourly MixID " + " " + "-" + " " + dataContext.obs_time_real;
              chart.data = dataContext.hourly;
              resetLabel.show();
              title.show();
            }
          });
        }
  
        function createSeries1(field, name) {
          // Set up series
          let series1 = chart.series.push(new am4charts.LineSeries());
          series1.name = name;
          series1.dataFields.valueY = field;
          series1.dataFields.categoryX = "obs_time_real";
          series1.yAxis = valueAxis2;
          series1.stroke = am4core.color("#D6E865");
          series1.fill = am4core.color("#D6E865");
  
          series1.tensionX = 0.9;
          series1.propertyFields.strokeDasharray = "lineDash";
          series1.strokeWidth = 3;
          series1.legendSettings.labelText = " [{stroke} {name}][/]";
          series1.legendSettings.valueText =
            "[#fff font-size:12px ] Throughput[/]";
  
          // Configure columns
          series1.tooltipText = " [font-size: 12px]Throughput:{valueY.formatNumber('#.00')}(TPH)";
          let bullet1 = series1.bullets.push(new am4charts.Bullet());
          bullet1.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
          let circle1 = bullet1.createChild(am4core.Circle);
          circle1.radius = 4;
          circle1.strokeWidth = 3;
          return series1;
        }
        for (let i = 0; i < this.TPName.length; i++) {
          let TPtonIdName = this.TPName[i];
          createSeries1(TPtonIdName, TPtonIdName);
        }
      }, 150);
    }


    DrawChartGAD() {
      setTimeout(() => {
        let ref = this;
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create("chartGad1", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.paddingRight = 15;
  
        // Add data
        var chartData = this.mergedData;
        chart.data = chartData;
  
        chart.colors.list = [
          am4core.color("#00ff00"),
          am4core.color("#03c03c"),
          am4core.color("#2e8b57"),
          am4core.color("#3fff00"),
          am4core.color("#059033"),
          am4core.color("#009900"),
          am4core.color("#008000"),
          am4core.color("#195905"),
          am4core.color("#8fd400"),
          am4core.color("#76ff7a"),
          am4core.color("#50c878"),
          am4core.color("#00ff7f"),
        ];
  
        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "obs_time_real";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 50;
        categoryAxis.numberFormatter.numberFormat = "#";
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.title.fontSize = 12;
        categoryAxis.title.fill = am4core.color("#FFF");
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
  
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = categoryAxis;
        chart.cursor.lineY.disabled = true;
        chart.cursor.behavior = "none";
  
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.marginRight = 50;
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");
        valueAxis.min = 0;
        valueAxis.title.text = "Gas Consumption(MMBTU)";
        valueAxis.title.fill = am4core.color("#FFF");
        valueAxis.title.fontSize = 12;
        valueAxis.cursorTooltipEnabled = false;
  
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.maxPrecision = 0;
        valueAxis2.title.text = "Drumshell Temperature (°F)";
        valueAxis2.title.fontSize = "12px";
        valueAxis2.title.fill = am4core.color("#FFF");
        valueAxis2.cursorTooltipEnabled = false;
        valueAxis2.renderer.opposite = true;
        valueAxis2.min = 0;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.renderer.labels.template.fontSize = 12;
        valueAxis2.renderer.labels.template.fill = am4core.color("#fff");
  
        let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis3.maxPrecision = 0;
        valueAxis3.title.text = "Ambient Temperature (°F)";
        valueAxis3.title.fontSize = "12px";
        valueAxis3.title.fill = am4core.color("#FFF");
        valueAxis3.cursorTooltipEnabled = false;
        valueAxis3.renderer.opposite = true;
        valueAxis3.min = 0;
        valueAxis3.syncWithAxis = valueAxis;
        valueAxis3.renderer.labels.template.fontSize = 12;
        valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
      
        var title = chart.titles.create();
        title.fill = am4core.color("#FFF");
        var resetLabel = chart.plotContainer.createChild(am4core.Label);
        resetLabel.text = "[bold]<< Back[/]";
        resetLabel.fill = am4core.color("#ffffff");
        resetLabel.x = -30;
        resetLabel.y = -30;
        resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        resetLabel.hide();
        resetLabel.events.on(
          "hit",
          function (ev) {
            resetLabel.hide();
            title.hide();
  
            chart.data = chartData;
          },
          this
        );
  
        interface DataContext {
          hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
          obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
          // Add other properties if necessary
        }
  
        // Create series
        function createSeries(field, name) {
          // Set up series
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.name = name;
          series.dataFields.valueY = field;
          series.dataFields.categoryX = "obs_time_real";
          series.sequencedInterpolation = true;
          series.stacked = true;
          series.strokeWidth = 1;
          series.legendSettings.labelText = " [{stroke} {name}][/]";
          series.legendSettings.valueText = "[#fff font-size:12px ] {name}[/]";
  
          // Configure columns
          series.columns.template.width = am4core.percent(40);
          // series.tooltipText = "{name}\n{valueY.formatNumber('#.')}";
          series.tooltipText =
            " [font-size: 12px]{name}\n{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
          return series;
        }
        for (let i = 0; i < this.GADIdnames.length; i++) {
          let GADIdName = this.GADIdnames[i];
          let series = createSeries(GADIdName, GADIdName);
          series.columns.template.events.on("hit", (ev) => {
            const dataContext = ev.target.dataItem.dataContext as DataContext;
            if (Array.isArray(dataContext.hourly)) {
              chart.titles.getIndex(0).text =
                " Hourly MixID " + " " + "-" + " " + dataContext.obs_time_real;
              chart.data = dataContext.hourly;
              resetLabel.show();
              title.show();
            }
          });
        }
  
        function createSeries1(field, name) {
          // Set up series
          let series1 = chart.series.push(new am4charts.LineSeries());
          series1.name = name;
          series1.dataFields.valueY = field;
          series1.dataFields.categoryX = "obs_time_real";
          series1.yAxis = valueAxis2;
          series1.stroke = am4core.color("#D6E865");
          series1.fill = am4core.color("#D6E865");
  
          series1.tensionX = 0.9;
          series1.propertyFields.strokeDasharray = "lineDash";
          series1.strokeWidth = 3;
          series1.legendSettings.labelText = " [{stroke} {name}][/]";
          series1.legendSettings.valueText =
            "[#fff font-size:12px ] Drumshell Temperature (°F)[/]";
  
          // Configure columns
          series1.tooltipText = " [font-size: 12px]Drumshell:{valueY.formatNumber('#.00')}(°F)";
          let bullet1 = series1.bullets.push(new am4charts.Bullet());
          bullet1.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
          let circle1 = bullet1.createChild(am4core.Circle);
          circle1.radius = 4;
          circle1.strokeWidth = 3;
          return series1;
        }
        for (let i = 0; i < this.DrumName.length; i++) {
          let DrumIdName = this.DrumName[i];
          createSeries1(DrumIdName, DrumIdName);
        }
  
        function createSeries2(field, name) {
          // Set up series
          let series2 = chart.series.push(new am4charts.LineSeries());
          series2.name = name;
          series2.dataFields.valueY = field;
          series2.dataFields.categoryX = "obs_time_real";
          series2.yAxis = valueAxis3;
          series2.stroke = am4core.color("#32CD30");
          series2.fill = am4core.color("#32CD30");
  
          series2.tensionX = 0.9;
          series2.propertyFields.strokeDasharray = "lineDash";
          series2.strokeWidth = 3;
          series2.legendSettings.labelText = " [{stroke} {name}][/]";
          series2.legendSettings.valueText =
            "[#fff font-size:12px ] Ambient Temperature (°F)[/]";
  
          // Configure columns
          series2.tooltipText = " [font-size: 12px]Ambient :{valueY.formatNumber('#.00')}(°F)";
          let bullet2 = series2.bullets.push(new am4charts.Bullet());
          bullet2.fill = am4core.color("#32CD30"); // tooltips grab fill from parent by default
          let circle2 = bullet2.createChild(am4core.Circle);
          circle2.radius = 4;
          circle2.strokeWidth = 3;
          return series2;
        }
        for (let i = 0; i < this.AmbName.length; i++) {
          let AmbIdName = this.AmbName[i];
          createSeries2(AmbIdName, AmbIdName);
        }
      }, 150);
    }

    DrawChartNPGAD() {
      setTimeout(() => {
        let ref = this;
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create("chartNPPGAD", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.paddingRight = 15;
  
        // Add data
        var chartData = this.mergedData;
        chart.data = chartData;
  
        chart.colors.list = [
          am4core.color("#00ff00"),
          am4core.color("#03c03c"),
          am4core.color("#2e8b57"),
          am4core.color("#3fff00"),
          am4core.color("#059033"),
          am4core.color("#009900"),
          am4core.color("#008000"),
          am4core.color("#195905"),
          am4core.color("#8fd400"),
          am4core.color("#76ff7a"),
          am4core.color("#50c878"),
          am4core.color("#00ff7f"),
        ];
  
        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "obs_time_real";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 50;
        categoryAxis.numberFormatter.numberFormat = "#";
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.title.fontSize = 12;
        categoryAxis.title.fill = am4core.color("#FFF");
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
  
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = categoryAxis;
        chart.cursor.lineY.disabled = true;
        chart.cursor.behavior = "none";
  
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.marginRight = 50;
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");
        valueAxis.min = 0;
        valueAxis.title.text = "Gas Consp (MMBTU)";
        valueAxis.title.fill = am4core.color("#FFF");
        valueAxis.title.fontSize = 12;
        valueAxis.cursorTooltipEnabled = false;
  
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.maxPrecision = 0;
        valueAxis2.title.text = "Drumshell Temp (°F)";
        valueAxis2.title.fontSize = "12px";
        valueAxis2.title.fill = am4core.color("#FFF");
        valueAxis2.cursorTooltipEnabled = false;
        valueAxis2.renderer.opposite = true;
        valueAxis2.min = 0;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.renderer.labels.template.fontSize = 12;
        valueAxis2.renderer.labels.template.fill = am4core.color("#fff");
  
        let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis3.maxPrecision = 0;
        valueAxis3.title.text = "Ambient Temp (°F)";
        valueAxis3.title.fontSize = "12px";
        valueAxis3.title.fill = am4core.color("#FFF");
        valueAxis3.cursorTooltipEnabled = false;
        valueAxis3.renderer.opposite = true;
        valueAxis3.min = 0;
        valueAxis3.syncWithAxis = valueAxis;
        valueAxis3.renderer.labels.template.fontSize = 12;
        valueAxis3.renderer.labels.template.fill = am4core.color("#fff");
  
        var title = chart.titles.create();
        title.fill = am4core.color("#FFF");
        var resetLabel = chart.plotContainer.createChild(am4core.Label);
        resetLabel.text = "[bold]<< Back[/]";
        resetLabel.fill = am4core.color("#ffffff");
        resetLabel.x = -30;
        resetLabel.y = -30;
        resetLabel.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        resetLabel.hide();
        resetLabel.events.on(
          "hit",
          function (ev) {
            resetLabel.hide();
            title.hide();
  
            chart.data = chartData;
          },
          this
        );
  
        interface DataContext {
          hourly: any[]; // Replace 'any' with the actual type of the 'hourly' property
          obs_time_real: string; // Replace 'string' with the actual type of 'obs_time_real' property if needed
          // Add other properties if necessary
        }
  
        // Create series
        function createSeries(field, name) {
          // Set up series
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.name = name;
          series.dataFields.valueY = field;
          series.dataFields.categoryX = "obs_time_real";
          series.sequencedInterpolation = true;
          series.stacked = true;
          series.strokeWidth = 1;
          series.legendSettings.labelText = " [{stroke} NP_GasConsp][/]";
          series.legendSettings.valueText = "[#fff font-size:12px ] NP_GasConsp[/]";
  
          // Configure columns
          series.columns.template.width = am4core.percent(40);
          // series.tooltipText = "{name}\n{valueY.formatNumber('#.')}";
          series.tooltipText =
            "[font-size: 12px]NP_GasConsp:{valueY.formatNumber('#.')}" + " " + "(MMBTU)";
          return series;
        }
        for (let i = 0; i < this.GADIdnames.length; i++) {
          let GADIdName = this.GADIdnames[i];
          let series = createSeries(GADIdName, GADIdName);
          series.columns.template.events.on("hit", (ev) => {
            const dataContext = ev.target.dataItem.dataContext as DataContext;
            if (Array.isArray(dataContext.hourly)) {
              chart.titles.getIndex(0).text =
                " Hourly MixID " + " " + "-" + " " + dataContext.obs_time_real;
              chart.data = dataContext.hourly;
              resetLabel.show();
              title.show();
            }
          });
        }
  
        function createSeries1(field, name) {
          // Set up series
          let series1 = chart.series.push(new am4charts.LineSeries());
          series1.name = name;
          series1.dataFields.valueY = field;
          series1.dataFields.categoryX = "obs_time_real";
          series1.yAxis = valueAxis2;
          series1.stroke = am4core.color("#D6E865");
          series1.fill = am4core.color("#D6E865");
  
          series1.tensionX = 0.9;
          series1.propertyFields.strokeDasharray = "lineDash";
          series1.strokeWidth = 3;
          series1.legendSettings.labelText = " [{stroke} {name}][/]";
          series1.legendSettings.valueText =
            "[#fff font-size:12px ] Drumshell Temp[/]";
  
          // Configure columns
          series1.tooltipText = "[font-size: 12px]Drumshell:{valueY.formatNumber('#.00')}(°F)";
          let bullet1 = series1.bullets.push(new am4charts.Bullet());
          bullet1.fill = am4core.color("#D6E865"); // tooltips grab fill from parent by default
          let circle1 = bullet1.createChild(am4core.Circle);
          circle1.radius = 4;
          circle1.strokeWidth = 3;
          return series1;
        }
        for (let i = 0; i < this.DrumName.length; i++) {
          let DrumIdName = this.DrumName[i];
          createSeries1(DrumIdName, DrumIdName);
        }
  
        function createSeries2(field, name) {
          // Set up series
          let series2 = chart.series.push(new am4charts.LineSeries());
          series2.name = name;
          series2.dataFields.valueY = field;
          series2.dataFields.categoryX = "obs_time_real";
          series2.yAxis = valueAxis3;
          series2.stroke = am4core.color("#32CD30");
          series2.fill = am4core.color("#32CD30");
  
          series2.tensionX = 0.9;
          series2.propertyFields.strokeDasharray = "lineDash";
          series2.strokeWidth = 3;
          series2.legendSettings.labelText = " [{stroke} {name}][/]";
          series2.legendSettings.valueText =
            "[#fff font-size:12px ] Ambient Temp[/]";
  
          // Configure columns
          series2.tooltipText = "[font-size: 12px]Ambient :{valueY.formatNumber('#.00')}(°F)";
          let bullet2 = series2.bullets.push(new am4charts.Bullet());
          bullet2.fill = am4core.color("#32CD30"); // tooltips grab fill from parent by default
          let circle2 = bullet2.createChild(am4core.Circle);
          circle2.radius = 4;
          circle2.strokeWidth = 3;
          return series2;
        }
        for (let i = 0; i < this.AmbName.length; i++) {
          let AmbIdName = this.AmbName[i];
          createSeries2(AmbIdName, AmbIdName);
        }
  
        
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
      }, 150);
    }
   
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiCallService2 } from '@app/routes/dashboard/pages/Real-time-perfomance/api-call-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

const baseURL1 = environment.baseUrl;

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent {
    plantData: any;
    predictedParameters: any;
    constructor(
        private http: HttpClient,
        public dialogRef: MatDialogRef<AlertComponent>,
        private router: Router,
        private apiCallService: ApiCallService2,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit(): void {
        this.getPlantData();
        
    }
    getPlantData() {
        this.apiCallService.getPlantData().subscribe(
            (data) => {
                this.plantData = data.plant_data;
                this.predictedParameters = data.prediction_recommendation;
            },
            (error) => {
                console.error('Error fetching plant data:', error);
            }
        );
    }
    
    closed() {
        if (this.plantData) {
            const postData = {
                choice: 2,
                record_time: this.plantData.record_time,
            };
            this.apiCallService.postChoice(postData).subscribe(
                response => {
                    console.log('Close button clicked, response:', response);
                    this.dialogRef.close();
                },
                error => {
                    console.error('Error posting choice:', error);
                    this.dialogRef.close(); // Close the dialog even if there's an error
                }
            );
        } else {
            this.dialogRef.close();
        }
    }

     reRender1() {
        if (this.plantData) {
            const postData = {
                choice: 1,
                record_time: this.plantData.record_time
            };

            this.apiCallService.postChoice(postData).subscribe(
                response => {
                    console.log('Close button clicked, response:', response);
                    this.dialogRef.close();
                    this.router.navigateByUrl('/', {skipLocationChange: true});
                    this.router.navigate(['dashboard/rt']);
                },
                error => {
                    console.error('Error posting choice:', error);
                    this.dialogRef.close(); // Close the dialog even if there's an error
                }
            );
        } else {
            this.dialogRef.close();
            this.router.navigateByUrl('/', {skipLocationChange: true});
            this.router.navigate(['dashboard/rt']);
        }
    }

     reRender2() {
        if (this.plantData) {
            const postData = {
                choice: 3,
                record_time: this.plantData.record_time
            };

            this.apiCallService.postChoice(postData).subscribe(
                response => {
                    console.log('Close button clicked, response:', response);
                    this.dialogRef.close();
                    this.router.navigateByUrl('/', {skipLocationChange: true});
                    this.router.navigate(['dashboard/rt']);
                },
                error => {
                    console.error('Error posting choice:', error);
                    this.dialogRef.close(); // Close the dialog even if there's an error
                }
            );
        } else {
            this.dialogRef.close();
            this.router.navigateByUrl('/', {skipLocationChange: true});
            this.router.navigate(['dashboard/rt']);
        }
    }
}



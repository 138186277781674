// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // lnsURL: 'https://demo1.livnsense.com/#/dashboard/landingpage', 
  baseUrl1: 'http://192.168.2.98:8000/',     
  baseUrl: 'https://api.asphalt-dev2.livnsense.com/',     
  baseUrl3: 'http://192.168.2.83:8000/',
   timeZone: 'America/Chicago',  
  // baseUrl: 'https://api.asphalt-dev2.livnsense.com/',
  // baseUrl1: 'https://192.168.1.156:8000/',  
  // baseUrl1: 'https://192.168.1.156:8000/',   
  // downloadUrl:'https://api.asphalt4.0.livnsense.com'
  // baseUrl2:'/fileserverimg/',
  // // baseUrl: 'http://192.168.1.15:9211/'
  // https://api.asphalt4.0.livnsense.com/ 
};